import axios from "axios"
import { ERROR_PAGE, SERVER_ERROR_PAGE } from "Container/BaseLayout/constants"

const betRequest = ({
  method,
  url,
  requestBody,
  params,
  history,
  ultimateLogout,
}) => {
  const token = localStorage.getItem(`token`)
  localStorage.setItem(`404`, `hidden`)
  localStorage.setItem(`500`, `hidden`)

  const isKz = localStorage.getItem(`local`) === `kz`

  return axios({
    method,
    url: `${process.env.REACT_APP_AUTH_URL}${isKz ? `/kz` : ``}/${url}`,
    data: requestBody,
    params,
    headers: {
      "Content-Type": `application/json`,
      token,
    },
  }).catch(error => {
    if (history && error.response) {
      if (error.response.status === 404) {
        localStorage.setItem(`404`, `show`)
        history.push(ERROR_PAGE)
      }
      if (error.response.status === 403) {
        ultimateLogout()
      }
      if (error.response.status === 500) {
        localStorage.setItem(`500`, `show`)
        history.push(SERVER_ERROR_PAGE)
      }
      throw error
    }
  })
}

export default betRequest
