import axios from "axios"
import omit from "lodash/omit"
import { LOGIN_ROUTE } from "Container/BaseLayout/constants"

export const login = ({ setAuth, loginData, setLoginData, isKz }) => {
  const requestBody = omit(loginData, [`errors`, `pending`])
  axios({
    method: `post`,
    url: `${process.env.REACT_APP_AUTH_URL}${isKz ? `/kz` : ``}/login`,
    data: requestBody,
    headers: {
      "Content-Type": `application/json`,
    },
  })
    .then(({ data }) => {
      localStorage.setItem(`token`, data.token)
      localStorage.setItem(`fullName`, data.fullName)
      localStorage.setItem(`username`, data.username)
      localStorage.setItem(`role`, data.role)
      setAuth({
        token: data.token,
        fullName: data.fullName,
      })
      setLoginData({ ...loginData, pending: false })
    })
    .catch(() => setLoginData({ ...loginData, errors: true, pending: false }))
}

export const logout = ({ history, setAuth, token }) => {
  const isKz = localStorage.getItem(`local`) === `kz`
  const clearAll = () => {
    localStorage.removeItem(`token`)
    localStorage.removeItem(`fullName`)
    localStorage.removeItem(`username`)
    localStorage.removeItem(`role`)
    localStorage.removeItem(`requestSearch`)
    setAuth({ token: null })
    history.push(LOGIN_ROUTE)
  }
  axios({
    method: `post`,
    url: `${process.env.REACT_APP_AUTH_URL}${isKz ? `/kz` : ``}/logout`,
    headers: {
      "Content-Type": `application/json`,
      token,
    },
  })
    .then(() => clearAll())
    .catch(() => clearAll())
}
