import React, { useEffect, useRef, useState } from "react"
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom"
import LoginPage from "Container/Login"
import useErrorBoundary from "use-error-boundary"
import { logout } from "Container/Login/api"

import { ReactComponent as LogOutIcon } from "Container/icons/log-out.svg"
import { ReactComponent as LogOutKZIcon } from "Container/icons/log-out-kz.svg"
import { ReactComponent as BetLogoIcon } from "Container/icons/bb-logo.svg"
import { ReactComponent as LotoClubLogoIcon } from "Container/icons/loto-club-logo.svg"
import NotExistPage, {
  ServerErrorPage,
  ErrorBoundaryPage,
} from "Components/ErrorPages"

import Request from "Container/Request"
import RequestForm from "Container/Request/Form"
import RequestPreview from "Container/Request/Preview"
import BlackList from "Container/BlackList"
import FilterStatistic from "Container/FilterStatistic"
import InfobipGetCallback from "Container/InfobipGetCallback"

import refreshTabCount from "./helper"

import {
  REQUEST_ROUTE,
  REQUEST_ROUTE_COPY,
  REQUEST_ROUTE_EDIT,
  REQUEST_ROUTE_PREVIEW,
  BLACK_LIST_ROUTE,
  FILTER_STATISTIC_ROUTE,
  LOGIN_ROUTE,
  ERROR_PAGE,
  SERVER_ERROR_PAGE,
  REQUEST_ROUTE_INFOBIP_GET_CALLBACK,
  isActive,
} from "./constants"
import "./styles.css"

const MainContent = ({ setAuth, isAuth, currentLocal }) => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { ErrorBoundary } = useErrorBoundary()
  const fullName = localStorage.getItem(`fullName`)
  const ultimateLogout = () => logout({ history, setAuth, token: isAuth.token })
  const isSupport = localStorage.getItem(`role`) === `SUPPORT`
  const isAdmin = localStorage.getItem(`role`) === `ADMIN`
  const isKz = currentLocal === `kz`

  const timerToClearSomewhere = useRef(0)

  // refresh data any 1,5 minutes
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isKz) {
      refreshTabCount({
        history,
        ultimateLogout,
      })
      timerToClearSomewhere.current = setInterval(
        () =>
          refreshTabCount({
            history,
            ultimateLogout,
            url: isKz ? `kz/request/all/new` : `request/all/new`,
          }),
        100000,
      )

      return () => {
        clearInterval(timerToClearSomewhere.current)
      }
    }
    document.title = `SMS`
  }, [])

  /* Stopping sending request "all/new" on the error page */
  useEffect(() => {
    if (isActive(SERVER_ERROR_PAGE, pathname))
      clearInterval(timerToClearSomewhere.current)
  }, [pathname])

  const BaseContainer = ({ children }) => {
    return (
      <div className={isKz ? `KZTheme` : `BBTheme`}>
        <div className="Header">
          <Link to={isSupport ? BLACK_LIST_ROUTE : REQUEST_ROUTE}>
            <div className="HeaderIcon">
              {isKz ? <LotoClubLogoIcon /> : <BetLogoIcon />}
            </div>
          </Link>
          {!isSupport && (
            <Link
              to={REQUEST_ROUTE}
              className="BaseLayoutHeaderNavigation"
              style={{
                color: isActive(REQUEST_ROUTE, pathname) ? `#f8e800` : `#fff`,
              }}
            >
              Заявки
            </Link>
          )}
          {!isKz && (
            <Link
              to={BLACK_LIST_ROUTE}
              className="BaseLayoutHeaderNavigation"
              style={{
                color: isActive(BLACK_LIST_ROUTE, pathname)
                  ? `#f8e800`
                  : `#fff`,
              }}
            >
              Черный список
            </Link>
          )}
          {!isSupport && (
            <Link
              to={FILTER_STATISTIC_ROUTE}
              className="BaseLayoutHeaderNavigation"
              style={{
                color: isActive(FILTER_STATISTIC_ROUTE, pathname)
                  ? `#f8e800`
                  : `#fff`,
              }}
            >
              Статистика по фильтрам
            </Link>
          )}
          <div className="LogOut">
            <div className="UserWrapper">{fullName}</div>
            {isKz ? (
              <LogOutKZIcon
                className="LogOutIcon"
                onClick={() => {
                  ultimateLogout()
                }}
              />
            ) : (
              <LogOutIcon
                className="LogOutIcon"
                onClick={() => {
                  ultimateLogout()
                }}
              />
            )}
          </div>
        </div>
        <ErrorBoundary
          render={() => children}
          renderError={() => (
            <div className="Content">
              <ErrorBoundaryPage />
            </div>
          )}
        />
      </div>
    )
  }
  return (
    <div>
      <Switch>
        <Switch>
          {!isKz && (
            <Route path={BLACK_LIST_ROUTE}>
              <BaseContainer>
                <div className="Content">
                  <BlackList ultimateLogout={ultimateLogout} />
                </div>
              </BaseContainer>
            </Route>
          )}
          {!isSupport && (
            <Route path={FILTER_STATISTIC_ROUTE}>
              <BaseContainer>
                <div className="Content">
                  <FilterStatistic ultimateLogout={ultimateLogout} />
                </div>
              </BaseContainer>
            </Route>
          )}
          {!isSupport && (
            <Route exact path={REQUEST_ROUTE}>
              <BaseContainer>
                <div className="Content">
                  <Request ultimateLogout={ultimateLogout} />
                </div>
              </BaseContainer>
            </Route>
          )}
          {isAdmin && (
            <Route exact path={REQUEST_ROUTE_COPY}>
              <BaseContainer>
                <RequestForm ultimateLogout={ultimateLogout} />
              </BaseContainer>
            </Route>
          )}
          {!isSupport && (
            <Route exact path={REQUEST_ROUTE_EDIT}>
              <BaseContainer>
                <RequestForm ultimateLogout={ultimateLogout} />
              </BaseContainer>
            </Route>
          )}
          {!isSupport && (
            <Route exact path={REQUEST_ROUTE_PREVIEW}>
              <BaseContainer>
                <div className="Content">
                  <RequestPreview ultimateLogout={ultimateLogout} />
                </div>
              </BaseContainer>
            </Route>
          )}
          {isAdmin && (
            <Route exact path={REQUEST_ROUTE_INFOBIP_GET_CALLBACK}>
              <BaseContainer>
                <div className="Content">
                  <InfobipGetCallback ultimateLogout={ultimateLogout} />
                </div>
              </BaseContainer>
            </Route>
          )}
          <Route exact path={ERROR_PAGE}>
            <BaseContainer>
              <div className="Content">
                <NotExistPage />
              </div>
            </BaseContainer>
          </Route>
          <Route exact path={SERVER_ERROR_PAGE}>
            <BaseContainer>
              <div className="Content">
                <ServerErrorPage />
              </div>
            </BaseContainer>
          </Route>
          <Redirect to={isSupport ? BLACK_LIST_ROUTE : REQUEST_ROUTE} />
        </Switch>
      </Switch>
    </div>
  )
}

const BaseLayout = () => {
  const [isAuth, setAuth] = useState({
    token: localStorage.getItem(`token`),
  })
  const isKzDomen = window.location.origin === `https://sms.lotoclub.kz`

  const [currentLocal, setCurrentLocal] = useState(
    localStorage.getItem(`local`) || (isKzDomen ? `kz` : `ru`),
  )

  useEffect(() => {
    if (!localStorage.getItem(`local`)) {
      localStorage.setItem(`local`, isKzDomen ? `kz` : `ru`)
    }
  }, [])
  return (
    <Router>
      <Switch>
        {isAuth.token ? (
          <MainContent
            setAuth={setAuth}
            isAuth={isAuth}
            currentLocal={currentLocal}
          />
        ) : (
          <div>
            <Route path={LOGIN_ROUTE}>
              <LoginPage
                isAuth={isAuth}
                setAuth={setAuth}
                currentLocal={currentLocal}
                setCurrentLocal={setCurrentLocal}
              />
            </Route>
            <Redirect to={LOGIN_ROUTE} />
          </div>
        )}
      </Switch>
    </Router>
  )
}

export default BaseLayout
